import eurekaMgrs from '@eureka/ui-managers';
import { useMemo } from 'react';
import { FEATURE_FLAGS } from 'constants/featureFlags.constants';

const { ConfigManager } = eurekaMgrs;
const { getFeatureToggle } = ConfigManager;

export const useFeatureFlag = () =>
  useMemo(
    () => ({
      [FEATURE_FLAGS.ENABLE_PX_SURVEY]: getFeatureToggle(FEATURE_FLAGS.ENABLE_PX_SURVEY),
    }),
    [],
  );
